// Generated by Framer (99ae5b3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["XLJbgx9RW"];

const serializationHash = "framer-cfOFD"

const variantClassNames = {XLJbgx9RW: "framer-v-1o8tr5r"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({category, height, id, width, ...props}) => { return {...props, F3BPvkx3o: category ?? props.F3BPvkx3o ?? "AI"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;category?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, F3BPvkx3o, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "XLJbgx9RW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1o8tr5r", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XLJbgx9RW"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-c77237f1-9a10-4d47-a1f4-3f12ca66275f, rgb(255, 223, 150))", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-letter-spacing": "0px", "--framer-line-height": "140%", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-88b875e7-8ca4-4532-b8a8-281609a0f010, rgb(15, 30, 53)))"}}>AI</motion.p></React.Fragment>} className={"framer-1yn1do2"} data-framer-name={"Categories"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"TBUxJgR9L"} style={{"--extracted-r6o4lv": "var(--token-88b875e7-8ca4-4532-b8a8-281609a0f010, rgb(15, 30, 53))"}} text={F3BPvkx3o} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cfOFD.framer-104qlw7, .framer-cfOFD .framer-104qlw7 { display: block; }", ".framer-cfOFD.framer-1o8tr5r { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 2px 8px 2px 8px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-cfOFD .framer-1yn1do2 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-cfOFD.framer-1o8tr5r { gap: 0px; } .framer-cfOFD.framer-1o8tr5r > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-cfOFD.framer-1o8tr5r > :first-child { margin-left: 0px; } .framer-cfOFD.framer-1o8tr5r > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 23.5
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"F3BPvkx3o":"category"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGiI5RLmUj: React.ComponentType<Props> = withCSS(Component, css, "framer-cfOFD") as typeof Component;
export default FramerGiI5RLmUj;

FramerGiI5RLmUj.displayName = "Elements/Category tag";

FramerGiI5RLmUj.defaultProps = {height: 23.5, width: 30};

addPropertyControls(FramerGiI5RLmUj, {F3BPvkx3o: {defaultValue: "AI", displayTextArea: false, title: "Category", type: ControlType.String}} as any)

addFonts(FramerGiI5RLmUj, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuI6fMZ1rib2Bg-4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})